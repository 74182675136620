


































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab3DiscussionTLC',
  components: {StembleLatex, CalculationInput, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        discussion: null,
        unknownNumber: null,
        composition: null,
        language: 'en',
      },
      options: [
        {
          text: 'Benzoic acid and benzophenone',
          value: 'benzophenone',
        },
        {
          text: 'Benzoic acid and biphenyl',
          value: 'biphenyl',
        },
      ],
      optionsFr: [
        {
          text: 'Acide benzoïque et benzophénone',
          value: 'benzophenone',
        },
        {
          text: 'Acide benzoïque et biphenyl',
          value: 'biphenyl',
        },
      ],
      rules: [(v: string) => v?.length <= 4500 || 'Max 4500 characters'],
      questions: [
        {
          en: 'Thoroughly explain the results from your TLC plates and use the results to identify the components of your unknown sample. Be sure to include your unknown number.',
          fr: "Expliquez en détail les résultats obtenus à partir de vos plaques de CCM et utilisez ces résultats pour identifier les composants de votre échantillon inconnu. N'oubliez pas de mentionner votre numéro d'inconnu.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
